import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import NavLinks from './NavLinks';

function SideBar({ isSidebarOpen, closeSidebar, openSidebar }) {
  const history = useHistory();

  return (
    <SwipeableDrawer
      open={isSidebarOpen}
      onClose={closeSidebar}
      onOpen={openSidebar}
    >
      <List>
        {NavLinks.sideNav.map((item, index) => {
          return (
            <ListItem button
              key={index}
              onClick={() => history.push(item.url)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          );
        })}
      </List>
    </SwipeableDrawer>
  )
}

SideBar.propTypes = {
  isSidebarOpen: PropTypes.bool,
  openSidebar: PropTypes.func,
  closeSidebar: PropTypes.func,
};


export default SideBar;