import { createMuiTheme } from '@material-ui/core/styles';

export const theme = {
  palette: {
    type: 'dark',
    primary: {
      light: '#F7F7F7',
      main: '#2979ff',
      dark: '#CCA950',
    },
    secondary: {
      light: '#2e75b6',
      main: '#f8f8f8',
      dark: '#ffc004',
    },
    altSecondary: {
      main: '#fec003',
    },
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    textTransform: 'none',
  },
  button: {
    primary: {
      '&:hover': {
        backgroundColor: '#ffc004',
        color: '#19cccc',
        background: '#ffc004',
      },
    },
    secondary: {
      '&:hover': {
        backgroundColor: '#19cccc',
        color: '#ffc004',
        background: '#ffc004',
      },
    },
  },
};

const muiTheme = createMuiTheme(theme);
export default muiTheme;
